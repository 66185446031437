import _get from 'lodash/get';
import {
  SET_MANUAL_QUOTA_LIST,
  TOGGLE_MANUAL_QUOTA_MODAL,
  MANUAL_QUOTA_CREATE_MODAL
} from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { sendRequest, completeRequest } from './request_state';
import RequestService from '../services/request_service';
const requestService = new RequestService();
const requestServiceNode = new RequestService('node');
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import manualQuotaSanitizer from '../helpers/sanitizers/manualQuotaFilters';
//* PURE ACTION FUNCTIONS (This ones call the reducer)
function setList(items, meta) {
  return {
    type: SET_MANUAL_QUOTA_LIST,
    data: items,
    meta
  };
}

function setModal(open, selected) {
  return {
    type: TOGGLE_MANUAL_QUOTA_MODAL,
    open: open,
    data: selected
  };
}
function createModal(open, isEditing, selected) {
  return {
    type: MANUAL_QUOTA_CREATE_MODAL,
    open: open,
    isEditing: isEditing,
    selected: selected
  };
}
// * OPEN/CLOSE MODAL *
export function toggleModal(open, revision, selected, isEditing) {
  return dispatch => {
    revision && dispatch(setModal(open, selected));
    !revision && dispatch(createModal(open, isEditing, selected));
  };
}

// * LIST MANUAL QUOTAS *
export const fetchManualQuotas = (params = {}) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/manual-quota?${stringifyQuery(params)}`);
  try {
    const payload = await requestServiceNode.get('manual-quotas', manualQuotaSanitizer(params), true);

    dispatch(setList(payload.data.rows, { nextPage: payload.data.nextPage }));
  } catch (errors) {
    dispatch(serverStatusError(errors));
  } finally {
    dispatch(completeRequest());
  }
};

// * CREATE MANUAL QUOTA *
export const manualQuotaCreate = (body = {}) => async dispatch => {
  dispatch(sendRequest('Create-Manual-Quota'));
  try {
    const payload = await requestServiceNode.post('manual-quotas', body, {}, true);
    dispatch(newAlert('success', 'success', payload.status));
    return { success: true, payload };
  } catch (errors) {
    //Errors
    const e = await errors.json();
    dispatch(newAlert('error', 'ERROR:', _get(e, 'errors.base', '')));
  } finally {
    dispatch(completeRequest('Create-Manual-Quota'));
  }
};

export const createMassiveManualQuota = (body = {}) => async dispatch => {
  dispatch(sendRequest('Create-Manual-Quotas'));
  try {
    const data = new FormData();
    data.append('file', body.file, body.file.name);
    data.append('printInBill', body.massivePrintInBill)
    const payload = await requestServiceNode.post(
      'manual-quotas/massive',
      data,
      {},
      true,
      {},
      false
    );
    dispatch(newAlert('success', 's', payload.data.message));
    return { success: true, payload };
  } catch (errors) {
    if (typeof errors !== 'undefined') {
      const e = await errors.json();
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
      return errors;
    }

    dispatch(
      newAlert(
        'error',
        'ERROR:',
        'Surgió un problema al subir el archivo. Intenté de nuevo.'
      )
    );
  } finally {
    dispatch(completeRequest('Create-Manual-Quotas'));
  }
};

// * MANUAL QUOTA REVISION *
export const manualQuotaRevision = (body = {}, type) => async dispatch => {
  try {
    let payload;
    dispatch(sendRequest('Manual-Quota-Revision'));
    if (type === 'revoke' || type === 'review') {
      const data = {
        id: body.id,
        manualQuotum: body.manual_quotum
      }

      const manualQuotaResponse = await requestServiceNode[type == 'revoke' ? 'delete' : 'post'](
        `manual-quotas/${type}`,
        data,
        {},
        true
      );

      const { data: info, message } = manualQuotaResponse.data;

      payload = info;
      dispatch(newAlert('success', 'success', message));
    } else {
      payload = await requestService.post(
        `manual_quota/${body.id}/${type}`,
        body,
        {},
        true
      );
      dispatch(newAlert('success', 'success', payload.status));
    }
    
    return { success: true, payload };
  } catch (errors) {
    const e = await errors.json();
    dispatch(newAlert('error', 'ERROR:', _get(e, 'errors.base', '')));
    return errors;
  } finally {
    dispatch(completeRequest('Manual-Quota-Revision'));
  }
};

// * DEACTIVATE MANUAL QUOTA *
export const manualQuotaDeactivate = (body = {}) => async dispatch => {
  dispatch(sendRequest('Manual-Quota-Revision'));
  try {
    const payload = await requestService.delete(
      `manual_quota/${body.id}/deactivate`,
      body,
      {},
      true
    );
    dispatch(newAlert('success', 'success', payload.status));
    return { success: true, payload };
  } catch (errors) {
    //Errors
    dispatch(serverStatusError(errors));
    return errors.json().then(e => {
      e.errors &&
        e.errors.status &&
        dispatch(newAlert('error', 'ERROR:', e.errors.status[0]));
      return e;
    });
  } finally {
    dispatch(completeRequest('Manual-Quota-Revision'));
  }
};

// Set up your root reducer here...
import { combineReducers } from 'redux';
import { authentication } from './authentication_reducer';
import { sales } from './sales_reducer';
import { client } from './client_reducer';
import { promissory } from './promissory_reducer';
import { user } from './user_reducer';
import { corporations } from './corporations_reducer';
import { alerts } from './alerts_reducer';
import { articles } from './articles_reducer';
import { brands } from './brands_reducer';
import { category } from './category_reducer';
import { suppliers } from './suppliers_reducer';
import { role } from './role_reducer';
import { pointOfSale } from './points_of_sale_reducer';
import { financialPlans } from './financial_plans_reducer';
import { commissions } from './commissions_reducer';
import { extraQuotum } from './extra_quotum_reducer';
import { queryPromissories } from './query_promissory_reducer';
import { geographicLocation } from './geographic_location_reducer';
import { contractStratum } from './contract_stratum_reducer';
import { globalSetting } from './global_setting_reducer';
import requestState from './request_state';
import { supplierArticles } from './supplier_articles_reducer';
import { portalFinancing } from './portal_financing_reducer';
import { stepper } from './stepper_reducer';
import { comment } from './comment_reducer';
import { quotaTransfer } from './quota_transfer_reducer';
import { salesRevoke } from './sales_revoke_reducer';
import { manualQuota } from './manual_quota_reducer';
import { homeVisits } from './home_visits_reducer';
import file from './file_reducer';
import { contract } from './contract_reducer';
import { phone } from './phone_reducer';
import { dashboard } from './dashboard_reducer';
import { dashboardSellers } from './dashboard_sellers_reducer';
import { queryQuotas } from './query_quota_reducer';
import { queryScoringQuotas } from './query_scoring_quota_reducer';
import { queryInventory } from './query_inventory_reducer';
import { notification } from './notification_reducer';
import { sessionData } from './session_reducer';
import { calculateInstalments } from './calculate_instalments_reducer';
import { announcement } from './announcement_reducer';
import { teleshopping } from './teleshopping_reducer';
import { pointUserAssociation } from './point_user_association_reducer';
import { schedulePointOfSaleConfigurations } from './schedule_point_of_sale_configuration_reducer';
import { scoringConfigurations } from './scoring_configuration_reducer';
import { insurances } from './insurances_reducer';
import { assignedQuotas } from './assigned_quotas_reducer';
import { policyParameters } from './policy_parameters_reducer';
import { subSegment } from './sub_segment_reducer';
import { scoring } from './scoring_reducer';
import { registerSchedule } from './register_schedule_reducer';
import { campaign } from './campaign_reducer';
import { supplierProposal } from './supplier_proposal_reducer';
import { lineExecutiveProfile } from './line_executive_profile_reducer';
import { exceptionConfigurations } from './location_exception_configuration_reducer';
import { secureCreditInstalmentsPercentage } from './secure_credit_instalments_percentage_reducer';
import { otpValidation } from './otp_validation_reducer';
import { bulkProcesses } from './bulk_processes_reducer';
import { systemStatus } from './system_status_reducer';
import { sinister } from './sinister_reducer';
import { secureProductMovements } from './secure_product_movements_reducer';
import { queryMegaQuotas } from './query_mega_quota_reducer';

const allReducers = combineReducers({
  authentication,
  role,
  sales,
  client,
  promissory,
  user,
  corporations,
  alerts,
  articles,
  brands,
  category,
  suppliers,
  pointOfSale,
  financialPlans,
  commissions,
  extraQuotum,
  queryPromissories,
  geographicLocation,
  contractStratum,
  globalSetting,
  requestState,
  supplierArticles,
  portalFinancing,
  stepper,
  comment,
  quotaTransfer,
  salesRevoke,
  manualQuota,
  file,
  contract,
  phone,
  dashboard,
  dashboardSellers,
  queryQuotas,
  queryScoringQuotas,
  queryInventory,
  notification,
  homeVisits,
  sessionData,
  calculateInstalments,
  announcement,
  teleshopping,
  pointUserAssociation,
  schedulePointOfSaleConfigurations,
  scoringConfigurations,
  insurances,
  assignedQuotas,
  policyParameters,
  subSegment,
  scoring,
  registerSchedule,
  campaign,
  supplierProposal,
  lineExecutiveProfile,
  exceptionConfigurations,
  secureCreditInstalmentsPercentage,
  otpValidation,
  bulkProcesses,
  systemStatus,
  sinister,
  secureProductMovements,
  queryMegaQuotas
});

export default (state, action) => {
  if (action.type === 'RESET_APP') {
    const { alerts, sessionData } = state;
    state = { alerts, sessionData };
    sessionStorage.clear();
  }

  return allReducers(state, action);
};
